@import './rem.scss';
@import 'colors.css';


@media (min-width: 320px) {
  .footer-flex {
    flex-direction: column;
    display: flex;
  }
  footer {
    padding: rem(40px) 0 rem(20px) 0;
  }
  footer .bottom {
    margin-top: rem(20px);
  }
  footer .bottom .currency {
    width: rem(278px);
    height: rem(82px);
    background: var(--currency-mobile) left top no-repeat;
    background-size: rem(278px);
  }
  footer .bottom .links {
    margin-top: rem(30px);
    text-align: center;
    display: block;
  }
  footer .bottom .links a, footer .bottom .links font {
    line-height: rem(26px) !important;
    margin-left: rem(10px);
    margin-right: rem(20px);
  }
  footer .row-menu {
    padding: rem(30px) 0 0 0;
  }
  footer .row-menu:nth-child(1) {
    order: 1;
  }
  footer .row-menu:nth-child(2) {
    order: 2;
  }
  footer .row-menu:nth-child(3) {
    order: 3;
  }

  footer .border-top {
    margin-top: rem(20px);
  }

  footer .bottom .currency-block {
    width: 100%;
    height: rem(36px);
    display: flex;
    flex-direction: row;
    gap: rem(10px);
    flex-wrap: wrap;
    margin-bottom: rem(30px);
  }

  footer .bottom .currency-block .icon {
    width: rem(65px);
    height: rem(34px);
    border-radius: rem(5px);
    background-size: rem(594px);
  }

  footer .bottom .currency-block .icon.visa {
    background: var(--currency) left center no-repeat;
    background-size: rem(594px);
  }

  footer .bottom .currency-block .icon.master {
    background: var(--currency) rem(-75px) center no-repeat;
    background-size: rem(594px);
  }

  footer .bottom .currency-block .icon.sepa {
    background: var(--currency) rem(-150px) center no-repeat;
    background-size: rem(592px);
  }

  footer .bottom .currency-block .icon.swift {
    background: var(--currency) rem(-225px) center no-repeat;
    background-size: rem(590px);
  }

  footer .bottom .currency-block .icon.bitcoin {
    background: var(--currency) rem(-300px) center no-repeat;
    background-size: rem(590px);
  }

  footer .bottom .currency-block .icon.paypal {
    background: var(--currency) rem(-375px) center no-repeat;
    background-size: rem(590px);
  }

  footer .bottom .currency-block .icon.crypto {
    background: var(--currency) rem(-525px) center no-repeat;
    background-size: rem(590px);
  }
}

@media (min-width: 720px) {
  footer {
    padding: rem(30px) 0 rem(0px) 0;
  }
  .footer-flex {
    flex-direction: row;
  }
  footer .bottom .currency {
    width: rem(594px);
    height: rem(36px);
    background: var(--currency) left center no-repeat;
    background-size: rem(594px);
  }

  footer .bottom .currency-block {
    width: rem(594px);
    height: rem(36px);
    display: flex;
    flex-direction: row;
    gap: rem(10px);
    flex-wrap: nowrap;
    margin-bottom: rem(10px);
  }

  footer .bottom .links {
    margin-top: rem(0px);
    text-align: right;
  }
  footer .bottom .links a, footer .bottom .links font {
    margin-left: rem(30px);
    margin-right: rem(0px);
  }
  footer .row-menu {
    padding: rem(40px) 0;
  }
  footer .bottom {
    margin-top: rem(0px);
  }
  footer .row-menu:nth-child(1) {
    order: 1;
  }
  footer .row-menu:nth-child(2) {
    order: 2;
  }
  footer .row-menu:nth-child(3) {
    order: 3;
  }
  footer .row-menu:nth-child(4) {
    order: 4;
  }

  footer .border-top {
    margin-top: rem(0px);
  }

}


footer {
  border-top: rem(1px) solid var(--hr-color);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

footer .row-menu {
  flex: 1;
  text-align: left;
  border: 0px solid red;
}

footer .row-menu .title {
  color: var(--text-color1);
  font-style: normal;
  font-weight: bold;
  font-size: rem(12px);
  line-height: rem(15px);
  letter-spacing: rem(1px);
  text-transform: uppercase;
  margin-bottom: rem(18px);
}

footer .row-menu a {
  margin-bottom: rem(10px);
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: rem(16px);
  line-height: rem(24px);
  color: var(--text-color2);
  text-decoration: none;
}

footer .row-menu a:hover {
  color: var(--top-menu-a-hover);
}

footer .row-menu .bold {
  display: block;
  color: var(--text-color1);
  font-style: normal;
  font-weight: 500;
  font-size: rem(20px);
  line-height: rem(30px);
  margin-bottom: rem(5px);
}

footer .row-menu .space {
  height: rem(15px);
}

footer .row-menu .text {
  display: block;
  font-family: Nunito, sans-serif;
  color: var(--text-color2);
  font-style: normal;
  font-weight: normal;
  font-size: rem(16px);
  line-height: rem(24px);
  margin-bottom: rem(5px);
}

footer .bottom {
  padding: rem(14px) 0 rem(16px) 0;
  display: flex;
  border: 0px sienna solid;
  justify-content: space-between;
  align-items: center;
}

footer .bottom .links {
  flex: 1;

}

footer .bottom .links a, footer .bottom .links font {
  color: var(--text-color2);
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: rem(14px);
  text-decoration: none;
  line-height: rem(16px);
}

footer .bottom .links a:hover {
  color: var(--top-menu-a-hover);
}

footer .border-top {
  border-top: rem(1px) solid var(--hr-color);
}

footer .social-block {
  width: rem(24px);
  height: rem(24px);
  margin-right: rem(10px);
}

footer .social-container {
  display: flex;
  margin-top: rem(15px);
}

footer .social-block.facebook {
  background: url("/img/facebook.svg");
  background-size: 100%;
}

footer .social-block.twitter {
  background: url("/img/twitter.svg");
  background-size: 100%;
}

footer .social-block.linkedin {
  background: url("/img/linkedin.svg");
  background-size: 100%;
}
