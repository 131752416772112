
:root {
    --light-general-dark: #00092E;
    --light-background-gradient1: #FAFBFD;
    --light-background-gradient2: #F1F3F6;
    --light-background-gradient3: #F8F9FC;
    --light-general-white: #FFFFFF;
    --light-general-blue: #3D60F6;
    --light-general-1gray: #707485;
    --light-general-2gray: #9EA2B4;
    --light-style-darkblue: #2A44A1;
    --light-style-blue: #264CED;
    --light-style-yellow: #FFBA40;
    --light-style-1gray: #C6CDDB;
    --light-style-2gray: #E9ECF4;
    --light-background-1type: #F9FAFD;
    --light-background-2type: #FAFBFD;
    --light-background-3type: #F3F4F9;
    --light-additional-background: #F7F7FA;

    --dark-general-dark: #00092E;
    --dark-general-white: #ffffff;
    --dark-general-blue: #3D60F6;
    --dark-general-1gray: #949494;
    --dark-general-2gray: #A1A1A1;
    --dark-style-darkblue: #2A44A1;
    --dark-style-blue: #264CED;
    --dark-style-yellow: #FFBA40;
    --dark-style-1gray: #303030;
    --dark-style-2gray: #242424;
    --dark-background-1type: #141414;
    --dark-background-2type: #171717;
    --dark-background-3type: #242424;
    --dark-additional-background: #303030;

    --loader-color1: #00000030;
    --loader-color2: #000000cc;

    --preorder-color: #FFBA40;
    --hot-color: #F83535;
    --new-color: #3D60F6;

    --preorder-background: #FFFAF1;
    --hot-background: #FFF9F9;
    --new-background: #FCFCFF;

    --bar-tags: #F7F7FC;

}

/*  light theme */
:root {
    --body-background: var(--light-general-white);
    --moon: url("/img/moon.svg");
    --header-background-menu: var(--light-general-white);
    --header-background-top: linear-gradient(110.91deg, var(--light-background-gradient1) 0%, var(--light-background-gradient2) 100%);
    --top-menu-a: var(--light-general-1gray);
    --top-menu-a-hover: var(--light-general-blue);
    --header-menu-a: var(--light-general-dark);
    --header-menu-a-hover: var(--light-general-blue);
    --header-language: var(--light-general-dark);
    --header-language-hover: var(--light-general-blue);
    --header-language-active: var(--light-general-blue);
    --header-language-background: var(--light-general-white);
    --logo: url("/img/logo.svg");
    --logo-ny: url("/img/logo-ny.svg");


    --button: var(--light-general-white);
    --button-background: var(--light-general-blue);
    --button-background-hover: var(--light-style-blue);

    --button2: var(--light-general-blue);
    --button2-hover: var(--light-style-blue);
    --button2-border: var(--light-general-blue);
    --button2-border-hover: var(--light-style-blue);

    --button3: var(--light-general-blue);
    --button3-hover: var(--light-style-blue);
    --button3-background: var(--light-background-3type);
    --button3-background-hover: var(--light-background-3type);


    --bardc: url("/img/dc22.png");
    --bar: url("/img/bar.svg");
    --bar-mobile: url("/img/bar-mobile.svg");

    --home-header-top: var(--light-general-1gray);
    --header-h1: var(--light-general-dark);
    --home-header-bottom: var(--light-general-1gray);

    --h2: var(--light-general-dark);
    --h-text: var(--light-general-1gray);
    --item-background: var(--light-general-white);
    --text-color1: var(--light-general-dark);
    --text-color2: var(--light-general-1gray);
    --read-more-color: var(--light-general-blue);
    --read-more-color-hover: var(--light-style-blue);

    --bar-faq: url("/img/bar-faq.svg");
    --bar-faq-mobile: url("/img/bar-faq-mobile.svg");

    --bar-adv: url("/img/bar-adv.svg");
    --bar-adv-mobile: url("/img/bar-adv-mobile.svg");

    --faq-plus: url("/img/faq-plus.svg");
    --faq-img: url("/img/faq-img.svg");
    --currency: url("/img/currency.svg");
    --currency-mobile: url("/img/currency-mobile.svg");
    --menu-mobile-icon: url("/img/menu-mobile.svg");

    --hr-color: var(--light-background-3type);
    --hr-color2: var(--light-background-3type);
    --bar-server: url("/img/bar-server.svg");
    --table-background: var(--light-general-white);
    --table-border-color: var(--light-background-3type);
    --table-th-color: var(--light-general-2gray);
    --table-td-color: var(--light-general-dark);
    --price-color: var(--light-general-blue);

    --os-bar: url("/img/bar-os.svg");
    --os-bar-mobile: url("/img/bar-os-mobile.svg");

    --os-freebsd: url("/img/os-freebsd.svg");
    --os-windows: url("/img/os-windows.svg");
    --os-debian: url("/img/os-debian.svg");
    --os-ubuntu: url("/img/os-ubuntu.svg");
    --os-vmware: url("/img/os-vmware.svg");
    --os-centos: url("/img/os-centos.svg");
    --os-openbsd: url("/img/os-openbsd.svg");

    --server-acc-image: url("/img/acc-image.svg");
    --server-hardware-image: url("/img/hardware-dedicated.svg");
    --dedicated-network-bar: url("/img/bar-network.svg");
    --dedicated-network-bar-mobile: url("/img/bar-network-mobile.svg");

    --sequrity-image: url("/img/sequrity-image.svg");


    --filter-background: var(--light-background-1type);
    --filter-top-border: var(--light-background-3type);

    --additional-item-bar: var(--light-additional-background);

    --bar-vps: url("/img/bar-vps.svg");
    --bar-vps-mobile: url("/img/bar-vps-mobile.svg");

    --bar-eff: url("/img/bar-eff.svg");
    --eff-image: url("/img/eff-image.svg");
    --workflow-image: url("/img/workflow-image.svg");
    --bar-vps-adv: url("/img/bar-adv.svg");
    --bar-vps-adv-mobile: url("/img/bar-adv-mobile.svg");

    --hardware-image: url("/img/hardware-image.svg");

    --bar-eff-mobile: url("/img/bar-eff-mobile.svg");

    --bar-cloud: url("/img/bar-cloud.svg");
    --bar-ddos: url("/img/bar-ddos.svg");
    --ddos-image: url("/img/ddos-image.svg");
    --bar-perfomance: url("/img/bar-perfomance.svg");
    --bar-perfomance-mobile: url("/img/bar-perfomance-mobile.svg");

    --juniper-logo: url("/img/juniper.png");
    --supermicro-logo: url("/img/supermicro.png");

    --perfomance-image: url("/img/perfomance-image.svg");

    --yes: url("/img/yes.svg");
    --no: url("/img/no.svg");

    --risks-image: url("/img/risks-image.svg");
    --risks-image-mobile: url("/img/risks-image-mobile.svg");

    --bar-risks: linear-gradient(90deg, var(--light-background-gradient1) 0%, var(--light-background-gradient2) 100%);
    --bar-colocation: url("/img/bar-colocation.svg");
    --bar-options: url("/img/bar-options.svg");
    --options-image: url("/img/options-image.svg");

    --work-image: url("/img/work-image.svg");
    --work-right: url("/img/work-right.svg");

    --bar-ddos-mobile: url("/img/bar-ddos-mobile.svg");
    --bar-colocation-mobile: url("/img/bar-colocation-mobile.svg");
    --bar-options-mobile: url("/img/bar-options-mobile.svg");
    --image-cdn: url("/img/cdn.svg");
    --map-cdn-image: url("/img/map-cdn.svg");
    --bar-cdn-terms: url("/img/bar-terms.svg");
    --cdn-terms-image: url("/img/cdn-terms-image.svg");
    --tech-image: url("/img/tech-image.svg");
    --bar-cdn-terms-mobile: url("/img/bar-cdn-terms-mobile.svg");
    --tab-background-active: var(--light-general-white);
    --tab-background: var(--light-background-2type);
    --bar-location: url("/img/bar-location.svg");
    --bar-location-mobile: url("/img/bar-location-mobile.svg");
    --location-image: url("/img/map_light.webp");
    --stability-image: url("/img/stability-image.svg");
    --placeholder-color: var(--light-general-2gray);
    --search-background: url("/img/search.svg");

    --bar-about: url("/img/bar-about.svg");
    --about-image: url("/img/about-image.svg");
    --ripe: url("/img/ripe.svg");
    --bar-service: url("/img/bar-service.svg");
    --human-image: url("/img/human-image.svg");

    --input-border-color: var(--light-style-2gray);
    --form-label-color: var(--light-general-2gray);
    --contact-image: url("/img/contact-image.svg");
    --input-text-color: var(--light-general-dark);

    --bar-contact: url("/img/bar-contact.svg");
    --bar-contact-mobile: url("/img/bar-contact-mobile.svg");
    --bar-contept: url("/img/bar-concept.svg");
    --concept-image: url("/img/concept-image.svg");
    --princip-image: url("/img/princip-image.svg");
    --hardware: url("/img/hardware-image.png");
    --bar-hardware-mobile: url("/img/bar-hardware-mobile.svg");
    --bar-status: url("/img/bar-status.svg");
    --status-image: url("/img/status-image.svg");
    --bar-why: url("/img/bar-why.svg");
    --why-image: url("/img/why-image.svg");
    --future-image: url("/img/future-image.svg");

    --vpn-image: url("/img/vpn-image.svg");
    --vpn-step-1: url("/img/vpn-steps-1.svg");
    --vpn-step-2: url("/img/vpn-steps-2.svg");
    --vpn-step-3: url("/img/vpn-steps-3.svg");

    --vpn-adv-bar: transparent;
    --vpn-adv-border: var(--light-style-2gray);
    --steps-item-bar: var(--light-general-white);

    --client-google: url("/img/client-google.svg");
    --client-appstore: url("/img/client-appstore.svg");
    --client-macos: url("/img/client-macos.svg");
    --client-linux: url("/img/client-linux.svg");
    --client-windows: url("/img/client-windows.svg");
    --client-appgalary: url("/img/client-appgalary.svg");

    --vpn-why-image1: url("/img/vpn-why1.svg");
    --vpn-why-image2: url("/img/vpn-why2.svg");

    --bar-poss: url("/img/bar-poss.svg");
    --bar-poss-mobile: url("/img/bar-poss-mobile.svg");
    --bar-network: url("/img/bar-network.svg");
    --bar-network-mobile: url("/img/bar-network-mobile.svg");
    --bar-partners: url("/img/bar-partners.svg");
    --bar-partners-mobile: url("/img/bar-partners-mobile.svg");
    --partners-image: url("/img/partners-image.svg");
    --partner-panel1-image: url("/img/panel1.png");
    --partner-panel2-image: url("/img/panel2.png");
    --bar-map: url("/img/bar-map.svg");
    --bar-map-mobile: url("/img/bar-map-mobile.svg");
    --star-inactive: url("/img/star-inactive.svg");
    --background-description-box: #ffffff;

    --storage-image: url("/img/storage-image.svg");

    --image-apps: url("/img/apps-image.svg");

    --storage-about: url("/img/storage-about-image.svg");

    --reseller-image: url("/img/reseller-image.svg");
    --reseller-image1: url("/img/reseller-image1.svg");
    --reseller-image2: url("/img/reseller-image2.svg");

    --storage-features-block1: url("/img/storage-features-block1.svg");
    --storage-features-block2: url("/img/storage-features-block2.svg");
    --storage-features-block3: url("/img/storage-features-block3.svg");
    --storage-features-block4: url("/img/storage-features-block4.svg");
    --storage-features-block5: url("/img/storage-features-block5.svg");
    --storage-features-block6: url("/img/storage-features-block6.svg");


    --crypto-monero: url("/img/crypto/monero.svg");
    --crypto-xrp: url("/img/crypto/xrp.svg");
    --crypto-bitcoin: url("/img/crypto/bitcoin.svg");
    --crypto-ethereum: url("/img/crypto/ethereum.svg");
    --crypto-usdt: url("/img/crypto/usdt.svg");
    --crypto-usdc: url("/img/crypto/usdc.svg");
    --crypto-solana: url("/img/crypto/solana.svg");
    --crypto-bnb: url("/img/crypto/bnb.svg");
    --crypto-litecoin: url("/img/crypto/litecoin.svg");
    --crypto-near: url("/img/crypto/near.svg");
    --crypto-stellar: url("/img/crypto/stellar.svg");
    --crypto-tron: url("/img/crypto/tron.svg");
    --crypto-dogecoin: url("/img/crypto/dogecoin.svg");
    --crypto-cardano: url("/img/crypto/cardano.svg");

    --header-ethereum: url("/img/crypto/header/ethereum.svg");
    --header-bitcoin: url("/img/crypto/header/bitcoin.svg");
    --header-usdt: url("/img/crypto/header/usdt.svg");
    --header-monero: url("/img/crypto/header/monero.svg");
    --header-xrp: url("/img/crypto/header/xrp.svg");
    --header-solana: url("/img/crypto/header/solana.svg");
    --header-usdc: url("/img/crypto/header/usdc.svg");
    --header-bnb: url("/img/crypto/header/bnb.svg");
    --header-litecoin: url("/img/crypto/header/litecoin.svg");
    --header-near: url("/img/crypto/header/near.svg");
    --header-stellar: url("/img/crypto/header/stellar.svg");
    --header-tron: url("/img/crypto/header/tron.svg");
    --header-dogecoin: url("/img/crypto/header/dogecoin.svg");
    --header-cardano: url("/img/crypto/header/cardano.svg");

    --face-bar: #cccccc90;
    --tag-bar: #cccccc55;

}

/* dark theme */
[data-theme="dark"] {
    --body-background: #1A1A1A;
    --header-background-menu: #1A1A1A;
    --header-background-top: var(--dark-background-1type);
    --moon: url("/img/sun.svg");
    --top-menu-a: var(--dark-general-1gray);
    --top-menu-a-hover: var(--dark-general-white);
    --header-menu-a: var(--dark-general-white);
    --header-menu-a-hover: var(--dark-general-blue);
    --header-language: var(--dark-general-1gray);
    --header-language-hover: var(--dark-general-white);
    --header-language-active: var(--dark-general-white);
    --header-language-background: var(--dark-background-3type);
    --logo: url("/img/logo-dark.svg");
    --logo-ny: url("/img/logo-dark-ny.svg");

    --button: var(--light-general-white);
    --button-background: var(--light-general-blue);
    --button-background-hover: var(--light-style-blue);

    --button2: var(--light-general-white);
    --button2-hover: var(--light-style-blue);
    --button2-border: var(--light-general-blue);
    --button2-border-hover: var(--light-style-blue);

    --button3: var(--light-general-blue);
    --button3-hover: var(--light-style-blue);
    --button3-background: var(--dark-style-1gray);
    --button3-background-hover: var(--dark-style-1gray);


    --bar: url("/img/bar-dark.svg");
    --bar-mobile: url("/img/bar-mobile-dark.svg");

    --home-header-top: var(--dark-general-1gray);
    --header-h1: var(--dark-general-white);
    --home-header-bottom: var(--dark-general-1gray);

    --h2: var(--dark-general-white);
    --h-text: var(--dark-general-1gray);
    --item-background: var(--dark-background-3type);
    --text-color1: var(--dark-general-white);
    --text-color2: var(--dark-general-1gray);
    --read-more-color: var(--light-general-blue);
    --read-more-color-hover: var(--light-general-white);

    --bar-faq: url("/img/bar-faq-dark.svg");
    --bar-faq-mobile: url("/img/bar-faq-mobile-dark.svg");

    --bar-adv: url("/img/bar-adv-dark.svg");
    --bar-adv-mobile: url("/img/bar-adv-mobile-dark.svg");

    --faq-plus: url("/img/faq-plus-dark.svg");
    --faq-img: url("/img/faq-img-dark.svg");
    --currency: url("/img/currency-dark.svg");
    --currency-mobile: url("/img/currency-mobile-dark.svg");
    --menu-mobile-icon: url("/img/menu-mobile-dark.svg");
    --colocation-image: url("/img/colocation-image.svg");

    --hr-color: var(--dark-style-2gray);
    --hr-color2: var(--dark-style-1gray);

    --bar-server: url("/img/bar-server-dark.svg");

    --table-background: var(--dark-background-3type);
    --table-border-color: var(--dark-style-1gray);
    --table-th-color: var(--dark-general-2gray);
    --table-td-color: var(--dark-general-white);
    --price-color: var(--dark-general-blue);


    --os-bar: url("/img/bar-os-dark.svg");
    --os-bar-mobile: url("/img/bar-os-mobile-dark.svg");


    --dedicated-network-bar: url("/img/bar-network-dark.svg");
    --dedicated-network-bar-mobile: url("/img/bar-network-mobile-dark.svg");

    --server-acc-image: url("/img/acc-image-dark.svg");
    --server-hardware-image: url("/img/hardware-dedicated-dark.svg");

    --sequrity-image: url("/img/sequrity-image-dark.svg");

    --filter-background: var(--dark-background-3type);
    --filter-top-border: var(--dark-style-2gray);

    --additional-item-bar: var(--dark-additional-background);

    --bar-vps: url("/img/bar-vps-dark.svg");
    --bar-vps-mobile: url("/img/bar-vps-mobile-dark.svg");


    --bar-eff: url("/img/bar-eff-dark.svg");
    --eff-image: url("/img/eff-image-dark.svg");
    --workflow-image: url("/img/workflow-image-dark.svg");

    --bar-vps-adv: url("/img/bar-adv-dark.svg");
    --bar-vps-adv-mobile: url("/img/bar-adv-mobile-dark.svg");

    --hardware-image: url("/img/hardware-image-dark.svg");

    --bar-eff-mobile: url("/img/bar-eff-mobile-dark.svg");

    --bar-cloud: url("/img/bar-cloud-dark.svg");
    --bar-ddos: url("/img/bar-ddos-dark.svg");
    --bar-ddos-mobile: url("/img/bar-ddos-mobile-dark.svg");

    --bar-perfomance: url("/img/bar-perfomance-dark.svg");

    --juniper-logo: url("/img/juniper-dark.png");

    --supermicro-logo: url("/img/supermicro-dark.png");
    --bar-perfomance-mobile: url("/img/bar-perfomance-mobile-dark.svg");

    --perfomance-image: url("/img/perfomance-image-dark.svg");

    --risks-image: url("/img/risks-image-dark.svg");
    --bar-risks: var(--dark-background-2type);
    --bar-colocation: url("/img/bar-colocation-dark.svg");
    --bar-options: url("/img/bar-options-dark.svg");
    --options-image: url("/img/options-image-dark.svg");

    --ddos-image: url("/img/ddos-image-dark.svg");

    --yes: url("/img/yes-dark.svg");
    --no: url("/img/no-dark.svg");
    --work-image: url("/img/work-image-dark.svg");
    --work-right: url("/img/work-right-dark.svg");
    --risks-image-mobile: url("/img/risks-image-mobile-dark.svg");
    --bar-colocation-mobile: url("/img/bar-colocation-mobile-dark.svg");
    --bar-options-mobile: url("/img/bar-options-mobile-dark.svg");

    --image-cdn: url("/img/cdn-dark.svg");
    --map-cdn-image: var(--item-background) url("/img/map-cdn-dark.svg");
    --bar-cdn-terms: url("/img/bar-cdn-terms-dark.svg");
    --cdn-terms-image: url("/img/cdn-terms-image-dark.svg");
    --tech-image: url("/img/tech-image-dark.svg");
    --bar-cdn-terms-mobile: url("/img/bar-cdn-terms-mobile-dark.svg");
    --bar-location: url("/img/bar-location-dark.svg");
    --bar-location-mobile: url("/img/bar-location-mobile-dark.svg");

    --location-image: url("/img/map_dark.webp");

    --tab-background-active: var(--dark-background-3type);
    --tab-background: var(--dark-background-2type);
    --stability-image: url("/img/stability-image-dark.svg");
    --search-background: url("/img/search-dark.svg");

    --bar-about: url("/img/bar-about-dark.svg");
    --about-image: url("/img/about-image-dark.svg");
    --ripe: url("/img/ripe-dark.svg");
    --bar-service: url("/img/bar-service-dark.svg");


    --input-border-color: var(--dark-style-2gray);
    --form-label-color: var(--light-general-2gray);
    --contact-image: url("/img/contact-image.svg");
    --input-background-color: var(--dark-background-1type);
    --input-text-color: var(--dark-general-white);


    --bar-contact: url("/img/bar-contact-dark.svg");
    --bar-contact-mobile: url("/img/bar-contact-mobile-dark.svg");
    --bar-contept: url("/img/bar-concept-dark.svg");
    --concept-image: url("/img/concept-image-dark.svg");
    --princip-image: url("/img/princip-image-dark.svg");
    --hardware: url("/img/hardware-image.png");
    --bar-hardware-mobile: url("/img/bar-hardware-mobile-dark.svg");
    --bar-status: url("/img/bar-status-dark.svg");
    --status-image: url("/img/status-image-dark.svg");
    --bar-why: url("/img/bar-why-dark.svg");
    --why-image: url("/img/why-image-dark.svg");
    --future-image: url("/img/future-image-dark.svg");

    --vpn-image: url("/img/vpn-image-dark.svg");
    --vpn-step-1: url("/img/vpn-steps-1-dark.svg");
    --vpn-step-2: url("/img/vpn-steps-2-dark.svg");
    --vpn-step-3: url("/img/vpn-steps-3-dark.svg");

    --client-google: url("/img/client-google-dark.svg");
    --client-appstore: url("/img/client-appstore-dark.svg");
    --client-macos: url("/img/client-macos-dark.svg");
    --client-linux: url("/img/client-linux-dark.svg");
    --client-windows: url("/img/client-windows-dark.svg");
    --client-appgalary: url("/img/client-appgalary-dark.svg");

    --vpn-why-image1: url("/img/vpn-why1-dark.svg");
    --vpn-why-image2: url("/img/vpn-why2-dark.svg");

    --vpn-adv-bar: var(--dark-style-2gray);
    --vpn-adv-border: transparent;
    --steps-item-bar: var(--dark-style-2gray);


    --bar-poss: url("/img/bar-poss-dark.svg");
    --bar-poss-mobile: url("/img/bar-poss-mobile-dark.svg");

    --bar-network: url("/img/bar-network-dark.svg");
    --bar-network-mobile: url("/img/bar-network-mobile-dark.svg");
    --bar-partners: url("/img/bar-network-dark.svg");
    --bar-partners-mobile: url("/img/bar-partners-mobile-dark.svg");
    --partners-image: url("/img/partners-image-dark.svg");
    --partner-panel1-image: url("/img/panel1-dark.png");
    --partner-panel2-image: url("/img/panel2-dark.png");
    --bar-map: url("/img/bar-map-dark.svg");
    --bar-map-mobile: url("/img/bar-map-mobile-dark.svg");
    --star-inactive: url("/img/star-inactive-dark.svg");

    --loader-color1: #ffffff30;
    --loader-color2: #ffffffcc;
    --background-description-box: #444444;

    --storage-image: url("/img/storage-image-dark.svg");
    --storage-about: url("/img/storage-about-image-dark.svg");

    --image-apps: url("/img/apps-image-dark.svg");

    --bar-tags: #303030;

    --reseller-image: url("/img/reseller-image-dark.svg");
    --reseller-image1: url("/img/reseller-image1-dark.svg");
    --reseller-image2: url("/img/reseller-image2-dark.svg");


    --crypto-monero: url("/img/crypto/monero.svg");
    --crypto-xrp: url("/img/crypto/xrp-dark.svg");
    --crypto-bitcoin: url("/img/crypto/bitcoin.svg");
    --crypto-ethereum: url("/img/crypto/ethereum.svg");
    --crypto-usdt: url("/img/crypto/usdt.svg");
    --crypto-usdc: url("/img/crypto/usdc.svg");
    --crypto-solana: url("/img/crypto/solana.svg");
    --crypto-bnb: url("/img/crypto/bnb.svg");
    --crypto-litecoin: url("/img/crypto/litecoin.svg");
    --crypto-near: url("/img/crypto/near-dark.svg");
    --crypto-stellar: url("/img/crypto/stellar-dark.svg");
    --crypto-tron: url("/img/crypto/tron.svg");
    --crypto-dogecoin: url("/img/crypto/dogecoin.svg");
    --crypto-cardano: url("/img/crypto/cardano.svg");

    --header-ethereum: url("/img/crypto/header/ethereum-dark.svg");
    --header-bitcoin: url("/img/crypto/header/bitcoin-dark.svg");
    --header-usdt: url("/img/crypto/header/usdt-dark.svg");
    --header-monero: url("/img/crypto/header/monero-dark.svg");
    --header-xrp: url("/img/crypto/header/xrp-dark.svg");
    --header-solana: url("/img/crypto/header/solana-dark.svg");
    --header-usdc: url("/img/crypto/header/usdc-dark.svg");
    --header-bnb: url("/img/crypto/header/bnb-dark.svg");
    --header-litecoin: url("/img/crypto/header/litecoin-dark.svg");
    --header-near: url("/img/crypto/header/near-dark.svg");
    --header-stellar: url("/img/crypto/header/stellar-dark.svg");
    --header-tron: url("/img/crypto/header/tron-dark.svg");
    --header-dogecoin: url("/img/crypto/header/dogecoin-dark.svg");
    --header-cardano: url("/img/crypto/header/cardano-dark.svg");

    --tag-bar: #cccccc33;

}


