@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;700&display=swap');

@import './rem.scss';
@import 'colors.css';


@media (min-width: 320px) {
  html, body {
    font-size: 14px;
  }
  .max-width {
    padding-left: rem(20px) !important;
    padding-right: rem(20px) !important;
  }
  h2 {
    font-size: rem(24px);
    line-height: rem(32px);
    font-weight: 500;
  }
  .left-right-container {
    flex-direction: column;
  }
  .left-right-container.only-row {
    flex-direction: row;
  }
  .vertical-padding {
    padding-top: rem(60px);
    padding-bottom: rem(60px);
  }

  .left-right-container .left, .left-right-container .right {
    text-align: left;
    //width: calc(100%);
  }
  .mobile-column-reverse {
    flex-direction: column-reverse !important;
  }
  .button {
    box-sizing: border-box;
    width: 100%;
  }
  .box {
    padding: rem(20px) rem(15px);
  }
}

@media (min-width: 720px) {
  html, body {
    font-size: 1vw;
  }
  .max-width {
    padding-left: rem(50px) !important;
    padding-right: rem(50px) !important;
    padding: rem(0px);
  }
  h2 {
    font-size: rem(40px);
    line-height: rem(50px);
    font-weight: 500;
  }
  .left-right-container {
    flex-direction: row;
  }
  .gap20 {
    gap: rem(20px);
  }
  .vertical-padding {
    padding-top: rem(100px);
    padding-bottom: rem(100px);
  }
  .left-right-container .left, .left-right-container .right {
    flex-direction: column;
    text-align: left;
    width: calc(100% / 2 - 30px);
  }
  .mobile-column-reverse {
    flex-direction: row !important;
  }
  .button {
    width: auto;
  }
  .box {
    padding: rem(25px);
  }
}

@media (min-width: 1400px) {
  html, body {
    font-size: 16px;
  }

}

html, body {
  background: var(--body-background);
  font-family: "Red Hat Display", "Open Sans", sans-serif;
  padding: 0;
  margin: 0;
  outline: none;
  transition: background 500ms ease-in-out, color 500ms ease, border 500ms ease-in-out;
}

.width {
  width: 100%;
}

.max-width {
  max-width: rem(1200px);
  width: 100%;
}

.center {
  align-content: center;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.border {
  border: 1px solid greenyellow;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.button, .button3 {
  text-decoration: none;
  white-space: nowrap;
  border: rem(1.5px) transparent solid;
  letter-spacing: rem(1px);
  text-align: center;
  border-radius: rem(5px);
  padding: rem(0px) rem(24px);
  height: rem(45px);
  background: var(--button-background);
  font-style: normal;
  font-weight: 500;
  font-size: rem(16px);
  line-height: rem(42px);
  color: var(--button);
  display: inline-block;
  text-transform: none !important;
  cursor: pointer;
  transition: color 500ms ease-in-out, color 200ms ease;
}

.button.max {
  box-sizing: border-box;
  width: 100% !important;
}

.button:hover, .button3:hover {
  background: var(--button-background-hover);
  box-shadow: rem(0px) rem(0px) rem(10px) #00000025;
}

.button3 {
  display: block;
}

.button2 {
  text-align: center;
  border-radius: rem(5px);
  border: rem(1.5px) solid #3D60F6;
  min-width: rem(120px);
  padding: rem(0px) rem(10px);
  height: rem(42px);
  background: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: rem(16px);
  line-height: rem(42px);
  color: var(--button2);;
  display: inline-block;
  cursor: pointer;
  transition: color 500ms ease-in-out, color 200ms ease;

}

.button2:hover {
  border: rem(1.5px) solid var(--button2-border-hover);
  color: var(--button2-hover);
  box-shadow: rem(0px) rem(0px) rem(10px) #00000025;
}

.button3 {
  text-align: center;
  border-radius: rem(5px);
  min-width: rem(86px);
  padding: rem(0px) rem(10px);
  height: rem(34px);
  background: var(--button3-background);
  font-style: normal;
  font-weight: 600;
  font-size: rem(16px);
  line-height: rem(34px);
  color: var(--button3);;
  display: inline-block;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  transition: color 500ms ease-in-out, color 200ms ease;

}

.button3:hover {
  background: var(--button3-background-hover);
  color: var(--button3-hover);
  box-shadow: rem(0px) rem(0px) rem(10px) #00000025;
}

.align-right {
  //margin-left: rem(20px);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.read-more, .read-more a, .show-full, .read-more-back {
  text-decoration: none !important;
  cursor: pointer;
  font-style: normal;
  width: auto;
  display: inline-block;
  font-weight: 500;
  font-size: rem(16px);
  line-height: rem(27px);
  color: var(--read-more-color);
  margin-top: rem(16px);
  padding-right: rem(26px);
  background: url("/img/read-more.svg") right rem(1.5px) no-repeat;
  background-size: rem(24px);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.read-more-back {
  background: url("/img/read-more-back.svg") left rem(1.5px) no-repeat;
  background-size: rem(23px);
  padding-left: rem(26px);

}

.text-align-center {
  text-align: center !important;
}

.read-more:hover, .show-full:hover {
  padding-right: rem(32px);
  color: var(--read-more-color-hover);
}

.read-more-back:hover {
  color: var(--read-more-color-hover);
}

.show-full {
  margin: rem(10px);
  display: inline-block;
  line-height: rem(26px);
}

h2 {
  color: var(--h2);
  padding: 0;
  margin: 0;
  margin-top: rem(-5px);
  margin-bottom: rem(20px);
}

.margin-bottom-zero {
  margin-bottom: 0 !important;
}

.margin-top-zero {
  margin-top: 0 !important;
}

.h-text, .h-text2, .h-text2-last, .h-text3, .h-text4 {
  display: block;
  font-size: rem(18px);
  line-height: rem(28px);
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: var(--h-text);
  max-width: rem(450px);
  margin-bottom: rem(24px);
}

.h-text3, .h-text4 {
  text-align: center;
  max-width: rem(650px);
  margin-bottom: rem(32px);
}

.h-text2-last {
  margin-bottom: rem(50px);
}

.h-text2 {
  max-width: 100% !important;
}

.nowrap {
  white-space: nowrap;
}

.border {
  border: 1px solid red !important;
}

.no-record {
  box-sizing: border-box;
  text-align: center;
  display: block;
  width: 100%;
  padding: rem(40px);
}

.align-left {
  text-align: left;
}

.left-right-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.left-right-container.stretch {
  align-items: stretch !important;
}

.left-right-container .left {
  flex: 0.9;
  //display: flex;
}

.left-right-container p {
  color: var(--text-color1);
  font-style: normal;
  font-weight: 600;
  font-size: rem(20px);
  line-height: rem(30px);
  margin: 0;
  margin-bottom: rem(8px);
  margin-top: rem(18px);
}

.max-width-500 {
  max-width: rem(600px);
}


.text-right {
  text-align: right !important;
}

.box {
  background: var(--item-background);
  box-sizing: border-box;
  border-top: rem(1px) solid var(--hr-color2);
  box-shadow: 0 rem(16px) rem(24px) rgba(0, 0, 0, 0.06), 0 rem(2px) rem(6px) rgba(0, 0, 0, 0.04), 0 0 rem(1px) rgba(0, 0, 0, 0.04);
  border-radius: rem(5px);
}

.box.clear {
  background: none;
}

.max-width-faq {
  max-width: rem(800px);
  width: 100%;

}

.text {
  color: var(--text-color2);
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: rem(18px);
  line-height: rem(28px);

}

.title {
  margin: 0;
  padding: 0;
  margin-bottom: rem(12px);
  color: var(--text-color1);
  font-style: normal;
  font-weight: 500;
  font-size: rem(20px);
  line-height: rem(30px);
}

.flex {
  flex: 1;
}

.star.inactive {
  width: rem(24px);
  height: rem(24px);
  background: var(--star-inactive) center center no-repeat;
  background-size: rem(24px);
}

.star.active {
  width: rem(24px);
  height: rem(24px);
  background: url("/img/star-active.svg") center center no-repeat;
  background-size: rem(24px);
}

.star-container {
  display: flex;
  flex-direction: row;
}

.loader,
.loader:after {
  border-radius: 50%;
}

.loader {
  flex-basis:  rem(10px);
  width: rem(10px) !important;
  height: rem(10px) !important;
  margin: auto auto;
  position: relative;
  border-top: rem(4px) solid var(--loader-color1);
  border-right: rem(4px) solid var(--loader-color1);
  border-bottom: rem(4px) solid var(--loader-color1);
  border-left: rem(4px) solid var(--loader-color2);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.loader.big {
  flex-basis:  rem(20px);
  width: rem(20px) !important;
  height: rem(20px) !important;
}

.loaderButton,
.loaderButton:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loaderButton {
  width: rem(10px);
  height: rem(10px);
  position: relative;
  top: rem(-30px);
  border-top: rem(4px) solid var(--loader-color1);
  border-right: rem(4px) solid var(--loader-color1);
  border-bottom: rem(4px) solid var(--loader-color1);
  border-left: rem(4px) solid var(--loader-color2);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.s404-container {
  width: 100%;
  height: 100%;
  text-align: center;
  color: var(--text-color2);
  font-size: rem(100px);
}
.pointer {
  cursor: pointer;
}

.preorder {
  color: var(--preorder-color);
  font-size: rem(13px);
}

.hot {
  color: var(--hot-color);
  font-size: rem(13px);
}

.new {
  color: var(--new-color);
  font-size: rem(13px);
}

.preorder-background {
  background: var(--preorder-background);
}

.hot-background {
  background: var(--hot-background);
}

.new-background {
  background: var(--new-background);
}

.url {
  text-decoration: underline;
  color: var(--light-general-blue);
  cursor: pointer;
}

.width-auto {
  width: auto !important;
}

span.reverse {
  unicode-bidi: bidi-override;
  direction: rtl;
}

.outer {
  //border: 1px solid blue;
  padding: rem(100px);
  padding-bottom: rem(200px);
}
.inner {
  width: 100px;
  //border: 1px solid red;
  margin: auto;
}

.old-price {
  font-size: rem(14px);
  color: red;
  text-decoration: line-through;
}

.old-price-pro {
  font-size: rem(14px);
  color: #7dad2a;
}
