@import './rem.scss';
@import 'colors.css';

@media (min-width: 320px) {
  header .menu-container {
    height: rem(60px);
  }
  h1 {
    margin: 0;
    margin-bottom: rem(32px);
    font-size: rem(32px);
    line-height: rem(38px);
  }
  .header.big {
    background: var(--bar-mobile) bottom center no-repeat;
    background-size: cover;
  }
  .header.server {
    background: var(--bar-mobile) bottom center no-repeat;
    background-size: cover;
  }
  .header.vps {
    background: var(--bar-vps-mobile) bottom center no-repeat;
    background-size: cover;
  }
  .header.cloud {
    background: var(--bar-vps-mobile) bottom center no-repeat;
    background-size: cover;
  }
  .header.ddos {
    background: var(--bar-ddos-mobile) center center no-repeat;
    background-size: cover;
  }
  .header.colocation {
    background: var(--bar-colocation-mobile) bottom center no-repeat;
    background-size: cover;
  }
  .header.cdn {
    background: var(--bar-ddos-mobile) center center no-repeat;
    background-size: cover;
  }
  .header.location {
    background: var(--bar-location-mobile);
    background-size: cover;
  }
  .header.about {
    background: var(--bar-vps-mobile);
    background-size: cover;
  }
  .header.apps {
    background: var(--bar-mobile);
    background-size: cover;
  }
  .header.hardware {
    background: var(--bar-hardware-mobile) center center no-repeat;
    background-size: cover;
  }
  .header.why {
    background: var(--bar-vps-mobile);
    background-size: cover;
  }
  .header.future {
    background: var(--bar-vps-mobile);
    background-size: cover;
  }
  .header.vpn {
    background-image: var(--bar-vps-mobile);
    background-size: cover;
  }
  .header.network {
    background: var(--bar-network-mobile);
    background-size: cover;
  }
  .header.partners, .header.reseller {
    background: var(--bar-partners-mobile);
    background-size: cover;
  }
  .header .flex-header {
    box-sizing: border-box;
    flex-direction: column-reverse;
    margin-top: rem(60px);
    margin-bottom: rem(60px);
  }
  .image-dc {
    background: url("/img/dc.png") center center no-repeat;
    height: rem(288px);
    width: rem(254px);
    background-size: 100%;
    border: 0px solid red;
  }
  .image-server {
    background: url("/img/dedicated-image.png") top rem(40px) center no-repeat;
    height: rem(290px);
    width: rem(280px);
    background-size: 100%;
    border: 0px solid red;
  }

  .image-server-crypto {
    height: rem(300px);
    width: rem(280px);
    border: 0px solid red;
    margin-bottom: rem(100px);

  }

  .image-server-crypto.bitcoin {
    background: var(--header-bitcoin) center center no-repeat;
    background-size: 100%;
  }

  .image-server-crypto.ethereum {
    background: var(--header-ethereum) center center no-repeat;
    background-size: 100%;
  }

  .image-server-crypto.usdt {
    background: var(--header-usdt) center center no-repeat;
    background-size: 100%;
  }

  .image-server-crypto.monero {
    background: var(--header-monero) center center no-repeat;
    background-size: 100%;
  }

  .image-server-crypto.xrp {
    background: var(--header-xrp) center center no-repeat;
    background-size: 100%;
  }

  .image-server-crypto.solana {
    background: var(--header-solana) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.usdc {
    background: var(--header-usdc) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.bnb {
    background: var(--header-bnb) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.litecoin {
    background: var(--header-litecoin) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.near {
    background: var(--header-near) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.stellar {
    background: var(--header-stellar) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.tron {
    background: var(--header-tron) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.dogecoin {
    background: var(--header-dogecoin) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.cardano {
    background: var(--header-cardano) center center no-repeat;
    background-size: 100%;
  }
  .image-vps {
    background: url("/img/vps-image.png") top center no-repeat;
    width: rem(254px);
    height: rem(308px);
    background-size: 100%;
    margin-left: rem(0px);
    border: 0px solid red;
    margin-bottom: rem(30px);
  }
  .image-cloud {
    background: url("/img/cloud-image.png") top center no-repeat;
    width: rem(235px);
    height: rem(308px);
    background-size: 100%;
    margin-left: rem(0px);
    margin-right: rem(10px);
    border: 0px solid red;
    margin-bottom: rem(30px);
  }
  .image-ddos {
    background: var(--ddos-image) top center no-repeat;
    position: relative;
    top: rem(0px);
    width: rem(220px);
    height: rem(224px);
    background-size: 100%;
    margin-left: rem(10px);
    margin-right: rem(0px);
    margin-bottom: rem(90px);
  }
  .image-colocation {
    background: url("/img/colocation-image.png") top center no-repeat;
    position: relative;
    bottom: rem(50px);
    top: rem(0px);
    width: rem(168px);
    height: rem(224px);
    background-size: 100%;
    border: 0px solid red;
    margin-bottom: rem(80px);
  }
  .image-cdn, .image-network {
    background: var(--image-cdn) top center no-repeat;
    position: relative;
    top: rem(0px);
    width: rem(220px);
    height: rem(224px);
    background-size: 100%;
    margin-left: rem(10px);
    margin-right: rem(0px);
    margin-bottom: rem(90px);
    border: 0px solid red;
  }
  .image-apps {
    background: var(--image-apps) top center no-repeat;
    position: relative;
    top: rem(0px);
    width: rem(290px);
    height: rem(294px);
    background-size: 100%;
    margin-left: rem(10px);
    margin-right: rem(0px);
    margin-bottom: rem(60px);
    border: 0px solid red;
  }
  .image-storage {
    background: var(--storage-image) top center no-repeat;
    width: rem(280px);
    height: rem(250px);
    background-size: 100%;
    margin-left: rem(0px);
    margin-right: rem(10px);
    border: 0px solid red;
    margin-bottom: rem(110px);
  }
  .image-location {
    border: 0px solid red;
    margin-bottom: rem(100px);
    background: var(--location-image) center center no-repeat;
    background-size: 100%;
    width: rem(300px);
    height: rem(191px);
    margin-left: rem(0px);
  }
  .image-about {
    border: 0px solid red;
    margin-right: rem(0px);
    background: var(--about-image) center center no-repeat;
    background-size: 100%;
    width: rem(250px);
    height: rem(225px);
    margin-bottom: rem(90px);
    margin-left: rem(0px);
  }
  .hardware-container .image-hardware {
    background: var(--hardware) center center no-repeat;
    background-size: 100%;
    width: rem(265px);
    height: rem(224px);
    margin-bottom: rem(70px);
  }
  .image-why {
    border: 0px solid red;
    margin-right: rem(0px);
    background: var(--why-image) center center no-repeat;
    background-size: 100%;
    width: rem(310px);
    height: rem(240px);
    margin-bottom: rem(70px);
  }
  .image-future {
    border: 0px solid red;
    margin-right: rem(0px);
    background: var(--future-image) center center no-repeat;
    background-size: 100%;
    width: rem(240px);
    height: rem(240px);
    margin-bottom: rem(80px);
    margin-left: rem(0px);
  }
  .image-vpn {
    border: 0px solid red;
    background: var(--vpn-image) center center no-repeat;
    background-size: 100%;
    width: rem(522px);
    height: rem(180px);
    margin-bottom: rem(100px);
    margin-left: rem(0px);
    margin-right: rem(10px);
  }
  .image-partners {
    border: 0px solid red;
    margin-right: rem(0px);
    background: var(--partners-image) center center no-repeat;
    background-size: 100%;
    width: rem(240px);
    height: rem(240px);
    margin-bottom: rem(80px);
    margin-left: rem(0px);
  }
  .image-container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .header .flex-header .left {
    position: relative;
    margin-top: rem(-60px);
  }
  .header .left {
    padding: rem(20px) rem(0px) 0 0;
  }
  header .lang-container .lang-container-menu {
    position: absolute;
    margin-top: rem(-32px);
    margin-left: rem(-12px);
    background: var(--header-language-background);
    padding: rem(0px) rem(12px);
    display: flex;
    flex-direction: column;
    text-align: left;
    border-radius: rem(5px);
    border: 1px solid #eeeeee00;
    z-index: 99999;
    width: rem(100px);
  }
  header .lang-container .lang-container-menu a, header .lang-container .lang-container-menu div {
    line-height: rem(40px);
    font-size: rem(14px);
    text-decoration: none;
    padding-left: rem(28px);
    margin-top: rem(2px);
    margin-bottom: rem(2px);
    color: var(--header-language);
    font-style: normal;
    font-weight: normal;
    user-select: none;
  }
  header .currency-container .currency-container-menu {
    position: absolute;
    margin-top: rem(-36px);
    margin-left: rem(-13px);
    background: var(--header-language-background);
    padding: rem(3px) rem(12px);
    display: flex;
    flex-direction: column;
    text-align: left;
    border-radius: rem(5px);
    border: 1px solid #eeeeee00;
  }
  header .currency-container .currency-container-menu div {
    line-height: rem(40px);
    font-size: rem(14px);
    text-decoration: none;
    padding-left: rem(22px);
    margin-top: rem(2px);
    margin-bottom: rem(2px);
    color: var(--header-language);
    font-style: normal;
    font-weight: normal;
    border: 0px solid red;
    cursor: pointer;
  }
  .header .image-reseller {
    background: var(--reseller-image) center center no-repeat;
    background-size: 100%;
    width: rem(260px);
    height: rem(200px);
    margin-right: rem(30px);
    margin-bottom: rem(100px);
  }
}

@media (min-width: 720px) {
  header .menu-container {
    height: rem(80px);
  }
  header {
    padding: rem(0px);
  }

  .header {
    width: 100%;
  }

  .header h1 {
    font-size: rem(40px);
    line-height: rem(40px);

  }
  .header h1.big {
    font-size: rem(56px);
    line-height: rem(70px);
  }
  .header.big {
    //background: var(--bar) center center no-repeat;
    background-image: var(--bardc);
    background-repeat: no-repeat;
    background-position: center right rem(-50px);
    background-size: 60%;
    position: relative;
    background-color: var(--filter-background);
    //background-color: red;
    opacity: 1;
  }
  //.header.big::before {
  //  content: "";
  //  position: absolute;
  //  background-image: var(--bardc);
  //  background-repeat: no-repeat;
  //  background-position: center right rem(-35px);
  //  background-size: 60%;
  //  background-color: var(--filter-background);
  //  //background-color: blue;
  //  top: 0px;
  //  right: 0px;
  //  bottom: 0px;
  //  left: 0px;
  //  opacity: 0.5;
  //  z-index: 0;
  //
  //}
  .header.server {
    background: var(--bar-server) center center no-repeat;
    background-size: cover;
    height: rem(500px);
  }
  .header.vps {
    background: var(--bar-vps) center center no-repeat;
    background-size: cover;
    height: rem(500px);
  }
  .header.cloud {
    background: var(--bar-cloud) center center no-repeat;
    background-size: cover;
    height: rem(500px);
  }
  .header.ddos {
    background: var(--bar-ddos) center center no-repeat;
    background-size: cover;
    height: rem(500px);
  }
  .image-vpn {
    border: 0px solid red;
    background: var(--vpn-image) center center no-repeat;
    background-size: 100%;
    width: rem(522px);
    height: rem(286px);
    margin-bottom: rem(30px);
    margin-left: rem(0px);
    margin-right: rem(10px);
  }
  .header.colocation {
    background: var(--bar-colocation) center center no-repeat;
    background-size: cover;
    height: rem(500px);
  }
  .header.cdn {
    background: var(--bar-ddos) center center no-repeat;
    background-size: cover;
    height: rem(500px);
  }
  .header.location {
    background: var(--bar-location) center center no-repeat;
    background-size: cover;
  }
  .header.about {
    background: var(--bar-location) center center no-repeat;
    background-size: cover;
    height: rem(500px);
  }
  .header.hardware {
    background: var(--bar-ddos) center center no-repeat;
    background-size: cover;
    height: rem(500px);
  }
  .header.why {
    background: var(--bar-why);
    background-size: cover;
  }
  .header.apps {
    background: var(--bar-why);
    background-size: cover;
  }
  .header.future {
    background: var(--bar-status);
    background-size: cover;
    height: rem(500px);
  }
  .header.vpn {
    background: var(--bar-status);
    background-size: cover;
    height: rem(500px);
  }
  .header.network {
    background: var(--bar-network);
    background-size: cover;
    height: rem(500px);
  }
  .header.partners {
    background: var(--bar-partners);
    background-size: cover;
    height: rem(500px);
  }
  .header.reseller {
    background: var(--bar-ddos);
    background-size: cover;
  }
  .header .flex-header {
    flex-direction: row;
    margin-top: rem(100px);
    margin-bottom: rem(100px);
  }
  .image-dc {
    margin-top: rem(0px);
    background: url("/img/dc.png") center rem(80px) no-repeat;
    width: rem(480px);
    height: rem(535px);
    background-size: 100%;
    margin-left: rem(100px);
  }
  .image-server {
    margin-top: rem(50px);
    background: url("/img/dedicated-image.png") center center no-repeat;
    height: rem(450px);
    width: rem(450px);
    background-size: 100%;
    margin-left: rem(100px);
    border: 0px solid red;
  }

  .image-server-crypto {
    margin-top: rem(0px);
    margin-bottom: rem(0px);
    margin-left: rem(100px);
    background: var(--header-bitcoin) center center no-repeat;
    height: rem(363px);
    width: rem(332px);
    background-size: 100%;
    border: 0px solid red;
  }
  .image-server-crypto.bitcoin {
    background: var(--header-bitcoin) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.ethereum {
    background: var(--header-ethereum) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.usdt {
    background: var(--header-usdt) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.xrp {
    background: var(--header-xrp) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.monero {
    background: var(--header-monero) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.solana {
    background: var(--header-solana) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.usdc {
    background: var(--header-usdc) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.bnb {
    background: var(--header-bnb) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.litecoin {
    background: var(--header-litecoin) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.near {
    background: var(--header-near) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.stellar {
    background: var(--header-stellar) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.tron {
    background: var(--header-tron) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.dogecoin {
    background: var(--header-dogecoin) center center no-repeat;
    background-size: 100%;
  }
  .image-server-crypto.cardano {
    background: var(--header-cardano) center center no-repeat;
    background-size: 100%;
  }
  .image-vps {
    position: relative;
    top: rem(50px);
    background: url("/img/vps-image.png") top center no-repeat;
    width: rem(351px);
    height: rem(383px);
    background-size: 100%;
    margin-left: rem(140px);
    margin-right: rem(50px);
    border: 0px solid red;
  }
  .image-cloud {
    background: url("/img/cloud-image.png") top center no-repeat;
    position: relative;
    top: rem(60px);
    width: rem(320px);
    height: rem(450px);
    background-size: 100%;
    margin-left: rem(140px);
    margin-right: rem(30px);
    border: 0px solid red;
    margin-bottom: rem(0px);
  }
  .image-ddos {
    background: var(--ddos-image) top center no-repeat;
    position: relative;
    top: rem(60px);
    width: rem(320px);
    height: rem(450px);
    background-size: 100%;
    margin-left: rem(140px);
    margin-right: rem(30px);
    border: 0px solid red;
    margin-bottom: rem(0px);
  }
  .image-colocation {
    background: url("/img/colocation-image.png") top center no-repeat;
    position: relative;
    top: rem(60px);
    width: rem(255px);
    height: rem(450px);
    background-size: 100%;
    margin-left: rem(150px);
    margin-right: rem(80px);
    border: 0px solid red;
    margin-bottom: rem(0px);
  }
  .image-storage {
    background: var(--storage-image) top center no-repeat;
    width: rem(398px);
    height: rem(355px);
    background-size: 100%;
    margin-left: rem(0px);
    margin-right: rem(10px);
    border: 0px solid red;
    margin-bottom: rem(30px);
  }
  .image-cdn, .image-network {
    background: var(--image-cdn) center center no-repeat;
    width: rem(384px);
    height: rem(360px);
    background-size: 100%;
    margin-left: rem(150px);
    margin-right: rem(20px);
    border: 0px solid red;
    margin-bottom: rem(0px);
  }
  .image-apps {
    background: var(--image-apps) top center no-repeat;
    position: relative;
    top: rem(0px);
    width: rem(384px);
    height: rem(360px);
    background-size: 100%;
    margin-left: rem(10px);
    margin-right: rem(0px);
    margin-bottom: rem(0px);
    border: 0px solid red;
  }
  .image-location {
    border: 0px solid red;
    margin-right: rem(0px);
    background: var(--location-image) center center no-repeat;
    background-size: 100%;
    width: rem(440px);
    height: rem(300px);
    margin-bottom: 0;
    margin-top: rem(30px);
    margin-left: rem(50px);
  }
  .image-about {
    border: 0px solid red;
    margin-right: rem(50px);
    background: var(--about-image) center center no-repeat;
    background-size: 100%;
    width: rem(470px);
    height: rem(300px);
    margin-left: rem(70px);
    margin-bottom: 0;
  }

  .hardware-container .image-hardware {
    background: var(--hardware) center center no-repeat;
    background-size: 100%;
    width: rem(420px);
    height: rem(340px);
    margin-left: rem(100px);
    margin-right: rem(50px);
    margin-bottom: rem(0px);
  }

  .image-why {
    border: 0px solid red;
    margin-right: rem(0px);
    background: var(--why-image) center center no-repeat;
    background-size: 100%;
    width: rem(445px);
    height: rem(290px);
    margin-top: rem(0px);
    margin-left: rem(30px);
    margin-bottom: 0;
  }

  .image-future {
    border: 0px solid red;
    margin-right: rem(50px);
    background: var(--future-image) center center no-repeat;
    background-size: 100%;
    width: rem(390px);
    height: rem(400px);
    margin-left: rem(100px);
    margin-bottom: 0;
  }
  .image-partners {
    border: 0px solid red;
    margin-right: rem(80px);
    background: var(--partners-image) center center no-repeat;
    background-size: 100%;
    width: rem(400px);
    height: rem(380px);
    margin-bottom: rem(0px);
    margin-left: rem(80px);
  }
  .image-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  .header .flex-header .left {
    position: inherit;
    margin-top: rem(0px);
  }
  .header .left {
    padding: 0;
  }
  header .lang-container .lang-container-menu {
    position: absolute;
    margin-top: rem(-27px);
    margin-left: rem(-13px);
    background: var(--header-language-background);
    padding: rem(3px) rem(12px);
    display: flex;
    flex-direction: column;
    text-align: left;
    border-radius: rem(5px);
    border: 1px solid #eeeeee00;
    z-index: 99999;
    width: auto;
  }
  header .lang-container .lang-container-menu a, header .lang-container .lang-container-menu div {
    line-height: rem(21px);
    font-size: rem(14px);
    text-decoration: none;
    padding-left: rem(28px);
    margin-top: rem(2px);
    margin-bottom: rem(2px);
    color: var(--header-language);
    font-style: normal;
    font-weight: normal;
    user-select: none;
  }
  header .currency-container .currency-container-menu {
    position: absolute;
    margin-top: rem(-27px);
    margin-left: rem(-13px);
    background: var(--header-language-background);
    padding: rem(3px) rem(12px);
    display: flex;
    flex-direction: column;
    text-align: left;
    border-radius: rem(5px);
    border: 1px solid #eeeeee00;
  }
  header .currency-container .currency-container-menu div {
    line-height: rem(21px);
    font-size: rem(14px);
    text-decoration: none;
    padding-left: rem(22px);
    margin-top: rem(2px);
    margin-bottom: rem(2px);
    color: var(--header-language);
    font-style: normal;
    font-weight: normal;
    border: 0px solid red;
    cursor: pointer;
  }
  .header .image-reseller {
    background: var(--reseller-image) center center no-repeat;
    background-size: 100%;
    width: rem(356px);
    height: rem(260px);
    margin-right: rem(120px);
    margin-bottom: 0;
  }
}

header .top-menu {
  font-family: 'Nunito', "Open Sans", sans-serif;
  font-weight: 400;
  width: 100%;
  height: rem(30px);
  line-height: rem(30px);
  background: var(--header-background-top);
  transition: background 500ms ease-in-out, color 200ms ease;
}

header .top-menu .left {
  text-align: left;
  flex: 1;
}

header .top-menu .left a {
  font-size: rem(14px);
  color: var(--top-menu-a);
  margin-right: rem(20px);
  text-decoration: none;
  text-align: left;
  transition: color 500ms ease-in-out, color 200ms ease;

}

header .top-menu .left a:hover {
  color: var(--top-menu-a-hover);
}

header .top-menu .moon {
  width: rem(24px);
  height: rem(24px);
  background: var(--moon) center center no-repeat;
  background-size: 80%;
  cursor: pointer;
}

header .lang-container {
  margin-left: rem(20px);
  font-size: rem(14px);
}

header .currency-container {
  margin-left: rem(20px);
  font-size: rem(14px);
  line-height: rem(22px);
}

header .lang-container .flag {
  padding-left: rem(28px);
  line-height: rem(21px);
  color: var(--header-language);
  text-decoration: none;
  text-align: left;
  transition: color 500ms ease-in-out, color 200ms ease;
}

header .currency-container .flag {
  padding-left: rem(22px);
  line-height: rem(21px);
  color: var(--header-language);
  text-decoration: none;
  text-align: left;
  transition: color 500ms ease-in-out, color 200ms ease;
}

header .lang-container .flag.en {
  background: url("/img/flags/en.svg") left center no-repeat;
  background-size: rem(21px);
}

header .lang-container .flag.ru {
  background: url("/img/flags/ru.svg") left center no-repeat;
  background-size: rem(21px);
}

header .lang-container .flag.de {
  background: url("/img/flags/de.svg") left center no-repeat;
  background-size: rem(21px);
}

header .lang-container .flag.es {
  background: url("/img/flags/es.svg") left center no-repeat;
  background-size: rem(21px);
}

header .lang-container .flag.fr {
  background: url("/img/flags/fr.svg") left center no-repeat;
  background-size: rem(21px);
}

header .lang-container .flag.it {
  background: url("/img/flags/it.svg") left center no-repeat;
  background-size: rem(21px);
}

header .currency-container .flag.usd {
  background: url("/img/flags/usd.svg") left center no-repeat;
  background-size: rem(16px);
}

header .currency-container .flag.eur {
  background: url("/img/flags/eur.svg") left center no-repeat;
  background-size: rem(16px);
}

header .currency-container .flag.chf {
  background: url("/img/flags/chf.svg") left center no-repeat;
  background-size: rem(16px);
}

header .currency-container .flag.btc {
  background: url("/img/flags/btc.svg") left center no-repeat;
  background-size: rem(16px);
}

header .currency-container .flag.ltc {
  background: url("/img/flags/ltc.svg") left center no-repeat;
  background-size: rem(16px);
}

header .currency-container .flag.eth {
  background: url("/img/flags/eth.svg") left center no-repeat;
  background-size: rem(16px);
}

header .currency-container .flag.rub {
  background: url("/img/flags/rub.svg") left center no-repeat;
  background-size: rem(16px);
}


header .lang-container .lang-container-menu a:hover, header .currency-container .currency-container-menu div:hover {
  color: var(--header-language-hover);
}

header .lang-container .lang-container-menu a.active, header .currency-container .currency-container-menu div.active {
  color: var(--header-language-active);
}

header .lang-container .lang-container-menu a.en, header .lang-container .lang-container-menu div.en {
  background: url("/img/flags/en.svg") left center no-repeat;
  background-size: rem(21px);
}

header .lang-container .lang-container-menu a.ru, header .lang-container .lang-container-menu div.ru {
  background: url("/img/flags/ru.svg") left center no-repeat;
  background-size: rem(21px);
}

header .lang-container .lang-container-menu a.de, header .lang-container .lang-container-menu div.de {
  background: url("/img/flags/de.svg") left center no-repeat;
  background-size: rem(21px);
}

header .lang-container .lang-container-menu a.es, header .lang-container .lang-container-menu div.es {
  background: url("/img/flags/es.svg") left center no-repeat;
  background-size: rem(21px);
}

header .lang-container .lang-container-menu a.it, header .lang-container .lang-container-menu div.it {
  background: url("/img/flags/it.svg") left center no-repeat;
  background-size: rem(21px);
}

header .lang-container .lang-container-menu a.fr, header .lang-container .lang-container-menu div.fr {
  background: url("/img/flags/fr.svg") left center no-repeat;
  background-size: rem(21px);
}

header .lang-container .lang-container-menu div.disable {
  opacity: 0.25;
}

header .currency-container .currency-container-menu div.usd {
  background: url("/img/flags/usd.svg") left center no-repeat;
  background-size: rem(16px);
}

header .currency-container .currency-container-menu div.rub {
  background: url("/img/flags/rub.svg") left center no-repeat;
  background-size: rem(16px);
}

header .currency-container .currency-container-menu div.eur {
  background: url("/img/flags/eur.svg") left center no-repeat;
  background-size: rem(16px);

}

header .currency-container .currency-container-menu div.chf {
  background: url("/img/flags/chf.svg") left center no-repeat;
  background-size: rem(16px);
}

header .currency-container .currency-container-menu div.btc {
  background: url("/img/flags/btc.svg") left center no-repeat;
  background-size: rem(16px);
}

header .currency-container .currency-container-menu div.ltc {
  background: url("/img/flags/ltc.svg") left center no-repeat;
  background-size: rem(16px);
}

header .currency-container .currency-container-menu div.eth {
  background: url("/img/flags/eth.svg") left center no-repeat;
  background-size: rem(16px);
}

header .lang-container .lang-container-menu hr, header .currency-container .currency-container-menu hr {
  width: 100%;
  border: 0;
  border-bottom: rem(1px) solid var(--hr-color);
  margin: rem(4px);
  padding: 0;
  position: relative;
  margin-left: rem(-1px);
}

header menu {
  padding: 0;
  margin: 0;
}

header .menu-container {
  background: var(--header-background-menu);
  transition: background 500ms ease-in-out, border-bottom 500ms ease-in-out;;
  border-bottom: rem(1px) solid var(--hr-color);
}

//header .logo {
//  cursor: pointer;
//  flex-shrink: 0;
//  border: 1px solid red;
//  width: rem(142px);
//  height: rem(40px);
//  background: var(--logo) center center no-repeat;
//  background-size: 100%;
//  transition: color 500ms ease-in-out, color 200ms ease;
//}

header .logo, header .logo-ny {
  margin-top: rem(-5px);
  cursor: pointer;
  flex-shrink: 0;
  border: 0px solid red;
  width: rem(142px);
  height: rem(48px);
  background: var(--logo) center center no-repeat;
  background-size: 100%;
  transition: color 500ms ease-in-out, color 200ms ease;
}

header .logo-ny {
  background: var(--logo-ny) center center no-repeat;
  background-size: 100%;
}

header .menu-display {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

header .menu-display a, header .menu-display .a {
  user-select: none;
  cursor: pointer;
  line-height: rem(24px);
  font-size: rem(16px);
  font-style: normal;
  font-weight: 500;
  color: var(--header-menu-a);
  margin-right: rem(24px);
  text-decoration: none;
  transition: color 500ms ease-in-out, color 200ms ease;
  z-index: 9999;
}

header .menu-display a:hover, header .menu-display a:hover {
  color: var(--header-menu-a-hover) !important;
}

header .top-menu .right {
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-basis: rem(400px);
  margin-right: rem(0px);
}

.shadow-box {
  box-shadow: rem(0px) rem(0px) rem(10px) #00000025;
}

header .mobile-menu-icon {
  cursor: pointer;
  display: flex;
  width: rem(24px);
  height: rem(24px);
  background: var(--menu-mobile-icon);
}

header .mobile-menu-item {
  height: rem(60px);
  width: 100%;
  display: block;
  box-sizing: border-box;
  line-height: rem(50px);
  padding: 0 rem(30px);
  border-bottom: rem(1px) solid var(--hr-color);
  transition: border-bottom 500ms ease-in-out, color 500ms ease;

}

header .mobile-menu-item {
  font-style: normal;
  font-weight: 600;
  font-size: rem(16px);
  text-decoration: none;
  line-height: rem(60px);
  color: var(--header-menu-a)
}

header .mobile-menu-item:hover {
  color: var(--header-menu-a-hover);
}

header .get-started-mobile {
  padding: rem(20px);
}

header .mobile-pref {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  height: rem(60px);
}

header .mobile-pref .moon {
  flex: 1;
  background: var(--moon) left center no-repeat;
  background-size: rem(24px);
  cursor: pointer;
  color: var(--text-color1);
  padding-left: rem(35px);
  padding-right: rem(20px);
  border: 0px solid red;
  display: block;
  font-size: rem(14px);
}

header .product-menu-container {
  padding: rem(14px) rem(24px) rem(14px) rem(24px);
  border: 0px solid red;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: rem(14px);
  margin-left: rem(-25px);
  text-align: left;
  border-top: rem(1px) solid var(--hr-color2);
  user-select: none;
  z-index: 1;
  //box-shadow: rem(0px) rem(0px) rem(10px) #00000025;
}

header .product-menu-container:after {
  content: '';
  background: var(--item-background);
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  display: block;
  //border: rem(1px) solid red;
  //border-bottom: rem(12px) solid var(--item-background);
  position: absolute;
  ////left: rem(50px);
  margin-left: rem(16px);
  top: rem(-10px);
  width: rem(18px);
  height: rem(18px);
  z-index: -1;
}

header .product-menu-container:before {
  content: '';
  background: var(--hr-color2);
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  display: block;
  //border: rem(1px) solid red;
  //border-bottom: rem(12px) solid var(--item-background);
  position: absolute;
  ////left: rem(50px);
  margin-left: rem(16px);
  top: rem(-11px);
  width: rem(18px);
  height: rem(18px);
  z-index: -1;
}

header .product-menu-container a {
  font-style: normal;
  font-weight: 500;
  font-size: rem(16px);
  text-decoration: none;
  line-height: rem(40px);
  color: var(--text-color2);
  white-space: nowrap;
  box-sizing: border-box;
}

header .product-menu-container a.nav-link.ico {
  padding-left: rem(50px);
  border: 0px solid red;
  box-sizing: border-box;

  .transition {
    transition: 3s;
  }
}

header .product-menu-container a.nav-link.ico.dedicated {
  background: url("/img/product-dedicated.svg") no-repeat left center;
  background-size: rem(36px);
}

header .product-menu-container a.nav-link.ico.dedicated:hover {
  background: url("/img/product-dedicated-active.svg") no-repeat left center;
  background-size: rem(36px);
}

header .product-menu-container a.nav-link.ico.cloud {
  background: url("/img/product-cloud.svg") no-repeat left center;
  background-size: rem(36px);
}

header .product-menu-container a.nav-link.ico.cloud:hover {
  background: url("/img/product-cloud-active.svg") no-repeat left center;
  background-size: rem(36px);
}

header .product-menu-container a.nav-link.ico.vps {
  background: url("/img/product-vps.svg") no-repeat left center;
  background-size: rem(36px);
}

header .product-menu-container a.nav-link.ico.vps:hover {
  background: url("/img/product-vps-active.svg") no-repeat left center;
  background-size: rem(36px);
}

header .product-menu-container a.nav-link.ico.storage {
  background: url("/img/product-storage.svg") no-repeat left center;
  background-size: rem(36px);
}

header .product-menu-container a.nav-link.ico.storage:hover {
  background: url("/img/product-storage-active.svg") no-repeat left center;
  background-size: rem(36px);
}

header .product-menu-container a.nav-link.ico.apps {
  background: url("/img/product-vpn.svg") no-repeat left center;
  background-size: rem(36px);
}

header .product-menu-container a.nav-link.ico.apps:hover {
  background: url("/img/product-vpn-active.svg") no-repeat left center;
  background-size: rem(36px);
}

header .product-menu-container a.nav-link.ico.ddos {
  background: url("/img/product-ddos.svg") no-repeat left center;
  background-size: rem(36px);
}

header .product-menu-container a.nav-link.ico.ddos:hover {
  background: url("/img/product-ddos-active.svg") no-repeat left center;
  background-size: rem(36px);
}

header .product-menu-container a.nav-link.ico.colocation {
  background: url("/img/product-colocation.svg") no-repeat left center;
  background-size: rem(36px);
}

header .product-menu-container a.nav-link.ico.colocation:hover {
  background: url("/img/product-colocation-active.svg") no-repeat left center;
  background-size: rem(36px);
}

header .product-menu-container a.nav-link.ico.vpn {
  background: url("/img/product-vpn.svg") no-repeat left center;
  background-size: rem(36px);
}

header .product-menu-container a.nav-link.ico.vpn:hover {
  background: url("/img/product-vpn-active.svg") no-repeat left center;
  background-size: rem(36px);
}

header .product-menu-container a.nav-link.ico.cdn {
  background: url("/img/product-cdn.svg") no-repeat left center;
  background-size: rem(36px);
}

header .product-menu-container a.nav-link.ico.cdn:hover {
  background: url("/img/product-cdn-active.svg") no-repeat left center;
  background-size: rem(36px);
}

header .product-menu-container a:hover {
  color: var(--header-menu-a-hover);
}

.header .flex-header {
  display: flex;
}

.header {
  width: 100%;
  transition: background 500ms ease-in-out, color 200ms ease;
  min-height: rem(500px);
  padding: rem(0px) 0;
  border: 0px solid red;
  overflow: hidden;
}

.header.big {
  //min-height: rem(700px);
}

.header .right {
  flex: 1;
  padding: rem(20px) rem(0px);
}

.header .left {
  text-align: left;
  flex: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .left .top {
  margin-bottom: rem(32px);
  font-style: normal;
  font-weight: bold;
  font-size: rem(12px);
  line-height: rem(15px);
  color: var(--home-header-top);
  letter-spacing: rem(1px);
  text-transform: uppercase;
  transition: color 500ms ease-in-out, color 200ms ease;
}

.header h1 {
  font-style: normal;
  font-weight: 600;
  color: var(--header-h1);
  transition: color 500ms ease-in-out, color 200ms ease;
}

.header h1.big {
  font-style: normal;
  font-weight: 600;
  color: var(--header-h1);
  transition: color 500ms ease-in-out, color 200ms ease;
}

.header .left .bottom {
  box-sizing: border-box;
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: rem(18px);
  line-height: rem(28px);
  color: var(--home-header-bottom);
  transition: color 500ms ease-in-out, color 200ms ease;
}

.header .right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dedicated-small {
  font-size: rem(12px);
}


.image-dc-new {
  width: rem(650px);
  height: rem(10px);
  //background: url("/img/dc222.png") no-repeat left center;
  background-size: 100%;
  border: 0px solid red;
}

